import { createSlice } from '@reduxjs/toolkit'

const mainSlice = createSlice({
  name: 'main',
  initialState: {
    slider: [],
  },
  reducers: {
    sliderAdded(state, { payload: { slider } }) {
      state.slider = slider
    },
  },
})

const getSlider = (state) => {
  return state.main.slider.length ? state.main.slider : null
}

const fetchSlider = () => async (dispatch, getState, { api }) => {
  const slider = getSlider(getState())

  if (slider) {
    return slider
  }

  const response = await api.slider()
  dispatch(mainSlice.actions.sliderAdded({ slider: response }))

  return response
}

export default {
  getSlider,
  fetchSlider,
  reducer: mainSlice.reducer,
}
