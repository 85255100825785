import React, { useRef } from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import NextLink from '../../../components/Next/NextLink'
import Image from '../../../components/Image'
import Title, { StyledTitle } from '../../../components/Title'
import WysiwygOutput, { StyledArticle } from '../../../components/WysiwygOutput'
import Container from '../../../components/Container'

const Home = () => {
  const about = useRef(null)

  return (
    <StyledHome>
      <StyledHomeGrid>
        <NextLink href="/box">
          <StyledHomeGridLeft>
            <StyledHomeGridTitle>
              <div>Ramen box</div>
            </StyledHomeGridTitle>

            <img src={require('../../../assets/images/qwerty1-min.jpg')} alt="ws" />
          </StyledHomeGridLeft>
        </NextLink>

        <StyledHomeGridRight>
          <NextLink href="/foodtrack">
            <StyledHomeGridCell>
              <StyledHomeMenuLabel>Фудтрак</StyledHomeMenuLabel>
              <img
                src={require('../../../assets/images/qwerty2-min.jpg')}
                alt="ws"
              />
            </StyledHomeGridCell>
          </NextLink>

          <StyledHomeGridInner>
            <NextLink href="/production">
              <StyledHomeGridInnerLeft>
                <StyledHomeMenuLabel>Производство</StyledHomeMenuLabel>
                <img
                  src={require('../../../assets/images/grid_1.jpg')}
                  alt="ws"
                />
              </StyledHomeGridInnerLeft>
            </NextLink>

            <NextLink href="/recipes">
              <StyledHomeGridInnerRight>
                <StyledHomeMenuLabel>Рецепты</StyledHomeMenuLabel>
                <img
                  src={require('../../../assets/images/grid_4.jpg')}
                  alt="ws"
                />
              </StyledHomeGridInnerRight>
            </NextLink>
          </StyledHomeGridInner>
        </StyledHomeGridRight>
      </StyledHomeGrid>

      <StyledHomeAbout id="about" ref={about}>
        <Container>
          <StyledHomeRow first>
            <StyledHomeCell className="max">
              <StyledCellLeftImage>
                <Image
                  placeholder={false}
                  responsive
                  width="962"
                  height="1162"
                  src={require('../../../assets/images/about-1-min.png')}
                  alt="about-1"
                />
              </StyledCellLeftImage>
            </StyledHomeCell>

            <StyledHomeCell className="topRight">
              <StyledHomeTitle>
                <Title>О рамене</Title>
              </StyledHomeTitle>

              <StyledHomeDescription>
                <WysiwygOutput>
                  <p>
                    Рамен не так давно на мировой гастрономической сцене, и
                    строгих правил, которым часто следуют японские блюда, здесь
                    нет: готовое блюдо рамен – это множество вариантов и
                    комбинаций, что позволяет нам экспериментировать и каждый
                    раз создавать новые вкусы и впечатления.
                  </p>
                </WysiwygOutput>
              </StyledHomeDescription>
            </StyledHomeCell>
          </StyledHomeRow>
        </Container>
      </StyledHomeAbout>
    </StyledHome>
  )
}

const StyledHomeGridTitle = styled.h1`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  color: white;
  font-family: ${(props) => props.fontFamilySecondary};
  font-size: 22px;
  line-height: 120%;
  margin-left: 17px;
  margin-bottom: 45px;
  text-transform: uppercase;
  font-weight: 700;

  ${up('md')} {
    margin-left: 45px;
    font-size: 24px;
    margin-bottom: 55px;
  }

  ${up('lg')} {
    font-size: 26px;
    margin-bottom: 60px;
  }

  ${up('xl')} {
    font-size: 36px;
    margin-left: 10%;
  }

  ${up('xxxl')} {
    margin-left: 50%;
  }

  &::after {
    position: absolute;
    height: 3px;
    background-color: ${(props) => props.theme.colorSecondary};
    content: '';
    width: 75px;
    bottom: -20px;
  }
`

const StyledHomeMenuLabel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px 15px 7px;
  z-index: 1;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  background-color: ${(props) => props.theme.colorSecondary};
  color: ${(props) => props.theme.green};
  transition: all ${(props) => props.theme.transitionTime};

  ${up('md')} {
    padding-left: 11px;
    font-size: 16px;
  }

  ${up('lg')} {
    font-size: 20px;
    padding-bottom: 9px;
  }

  ${up('xxxl')} {
    font-size: 24px;
    padding: 9px 29px 11px 25px;
  }
`

const StyledHomeGridCell = styled.a`
  display: block;
  text-decoration: none;
  position: relative;
  padding-bottom: 50%;
  overflow: hidden;
  > img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1.5s ease;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`

const StyledHomeGrid = styled.div`
  font-family: ${(props) => props.theme.fontFamilySecondary};
  ${up('md')} {
    display: flex;
    flex-wrap: nowrap;
  }
`
const StyledHomeGridLeft = styled.a`
  display: block;
  text-decoration: none;
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  > img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1.5s ease;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  ${up('md')} {
    padding-bottom: 50%;
    height: auto;
    width: 50%;
  }
`

const StyledHomeGridRight = styled.div`
  ${up('md')} {
    height: auto;
    width: 50%;
  }
`
const StyledHomeGridInner = styled.div`
  display: flex;
`

const StyledHomeGridInnerLeft = styled.a`
  position: relative;
  width: 50%;
  padding-bottom: 50%;
  overflow: hidden;
  display: block;
  text-decoration: none;
  > img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1.5s ease;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`

const StyledHomeGridInnerRight = styled.a`
  position: relative;
  width: 50%;
  padding-bottom: 50%;
  margin-top: -34px;
  overflow: hidden;
  display: block;
  text-decoration: none;

  > img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1.5s ease;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  ${up('md')} {
    margin-top: -38px;
  }

  ${up('lg')} {
    margin-top: -43px;
  }

  ${up('xxxl')} {
    margin-top: -66px;
  }

  ${StyledHomeMenuLabel} {
    bottom: auto;
    top: 0;
  }
`

const StyledPartnerLink = styled.a`
  display: inline-flex;
  transition: opacity ${(props) => props.theme.transitionTime};
  max-width: 140px;
  max-height: 90px;
  width: 100%;
  justify-content: center;

  &:hover {
    opacity: ${(props) => props.theme.opacity};
  }
`
const StyledPartnersTitle = styled.div`
  text-align: center;
  margin-bottom: 20px;

  ${up('md')} {
    margin-bottom: 40px;
  }

  ${up('xl')} {
    margin-bottom: 50px;
  }

  ${StyledTitle} {
    color: ${(props) => props.theme.colorPrimary};

    &::after {
      display: none;
    }
  }
`
const StyledPartners = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;

  ${up('md')} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  ${up('lg')} {
    padding-top: 80px;
    padding-bottom: 100px;
  }
`
const StyledPartnersGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -20px -20px;
`

const StyledPartnersCell = styled.div`
  width: 50%;
  padding: 20px 20px;
  font-size: 0;
  line-height: 0;
  text-align: center;

  ${up('sm')} {
    width: 25%;
  }

  ${up('lg')} {
    width: 16.666666%;
  }
`
const StyledCellLeftImage = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: -75px;
  position: relative;
  right: -50px;

  ${up('md')} {
    padding-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    right: 0;
    margin-left: -100px;
    margin-top: -30px;
  }

  ${up('xl')} {
    padding-top: 85px;
    margin-left: -180px;
    margin-right: -180px;
  }
`

const StyledHomeDescription = styled.div`
  ${up('md')} {
    max-width: 275px;
  }
`

const StyledHomeImage = styled.div`
  margin-top: 100px;
  margin-left: -20px;
  margin-right: -20px;

  ${up('md')} {
    margin-top: 0;
    padding-top: 7px;
    display: block;
    margin-left: auto;
    max-width: 400px;
    margin-right: -40px;
  }

  ${up('lg')} {
    margin-right: 0;
  }

  ${up('xl')} {
    max-width: none;
  }
`

const StyledHomeTitle = styled.div`
  margin-bottom: 30px;

  ${up('md')} {
    margin-bottom: 30px;
  }

  ${up('lg')} {
    margin-bottom: 30px;
  }

  ${up('xl')} {
    margin-bottom: 38px;
  }
`

const StyledHomeCell = styled.div`
  ${up('md')} {
    padding-left: 12px;
    padding-right: 12px;
    width: 50%;
  }
  ${up('xl')} {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.topRight {
    ${up('md')} {
      padding-top: 30px;
      padding-left: 60px;
    }
    ${up('lg')} {
      padding-left: 120px;
    }
    ${up('xl')} {
      padding-left: 250px;
      padding-top: 250px;
    }
  }

  &.bottomLeft {
    ${up('lg')} {
      padding-left: 100px;
    }
  }
`

const StyledHomeRow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.first ? 'column-reverse' : 'column')};
  ${up('md')} {
    padding-top: ${(props) => (props.second ? '100px' : '0')};
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-left: -12px;
    margin-right: -12px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;

    ${up('xl')} {
      margin-bottom: 150px;
    }
  }

  .max {
    ${up('md')} {
        max-width: 570px;
      }
  }

  &:first-child {
    background-image: url(${require('../../../assets/images/about-3-min.png')});
    background-size: 160px 160px;
    background-repeat: no-repeat;
    background-position: 100% 0%;

    ${up('md')} {
      background-image: url(${require('../../../assets/images/about-3-min.png')});
      background-size: 200px 200px;
      background-repeat: no-repeat;
      background-position: 100% 0%;
    }
    ${up('lg')} {
      background-size: 270px 270px;
    }
    }

    ${up('xl')} {
      background-size: 350px 350px !important;
    }
  }

  &:last-child {
      background-image: url(${require('../../../assets/images/about-4-min.png')});
      background-size: 150px 150px;
      background-repeat: no-repeat;
      background-position: 100% 270px;

    ${up('md')} {
      background-image: url(${require('../../../assets/images/about-4-min.png')});
      background-size: 200px 175px;
      background-repeat: no-repeat;
      background-position: 0% 0%;
    }
    ${up('lg')} {
      background-size: 235px 235px !important;
    }

    ${up('xl')} {
      margin-top: -100px;
      background-size: 235px 235px !important;
      background-position: 38% 0%;
    }
  }
`

const StyledHomeAbout = styled.div`
  background-color: ${(props) => props.theme.colorPrimary};
  padding-bottom: 0px;
  padding-top: 50px;

  ${up('md')} {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  ${up('lg')} {
    padding-bottom: 140px;
  }

  ${up('xl')} {
  }

  ${StyledArticle} {
    color: ${(props) => props.theme.colorSecondary};
  }

  ${StyledTitle} {
    color: ${(props) => props.theme.colorSecondary};
  }
`

const StyledHome = styled.div`
  overflow: hidden;

  .relative {
    position: relative;

    ${up('xl')} {
      left: 10%;
    }
    ${up('xxxl')} {
      left: 5%;
    }
  }
`

Home.defaultProps = {}

Home.propTypes = {}

export default Home
