import Home from '../features/Main/components/Home'
import main from '../features/Main/MainSlice'

function HomePage() {
  return <Home />
}

HomePage.getInitialProps = async ({ reduxStore }) => {
  await reduxStore.dispatch(main.fetchSlider())
  return {
    metaTitle: 'Главная',
  }
}

HomePage.propTypes = {}

export default HomePage
