import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Image = ({
  width,
  height,
  src,
  src2x,
  webp,
  webp2x,
  alt,
  responsive,
  placeholder,
}) => {
  function getSrc(x1, x2) {
    const values = []
    if (x1) values.push(x1)
    if (x2) values.push(`${x2} 2x`)

    return values.length ? values.join(', ') : null
  }

  const srcSet = getSrc(src, src2x)
  const webpSrcSet = getSrc(webp, webp2x)
  const isEmpty = !(srcSet || webpSrcSet)
  const isWidthAuto = width === 'auto'
  const isHeightAuto = height === 'auto'
  const isWidthPercent = `${width}`.endsWith('%')
  const isHeightPercent = `${height}`.endsWith('%')

  const styles = (() => {
    if (responsive) {
      return {
        paddingTop: `${(+height / +width) * 100}%`,
      }
    }
    return {
      width: (() => {
        if (isWidthAuto) {
          return 'auto'
        }
        if (isWidthPercent) {
          return width
        }
        return width ? `${width}px` : undefined
      })(),
      height: (() => {
        if (isHeightAuto) {
          return 'auto'
        }
        if (isHeightPercent) {
          return height
        }
        return height ? `${height}px` : undefined
      })(),
    }
  })()

  const computedWidth = width && !isWidthAuto ? width : undefined
  const computedHeight = height && !isHeightAuto ? height : undefined

  return (
    <StyledImage
      style={styles}
      responsive={responsive}
      empty={isEmpty}
      auto={isWidthAuto || isHeightAuto}
      hasPlaceholder={placeholder}
    >
      {isEmpty ? (
        ''
      ) : (
        <picture>
          {webpSrcSet && <source srcSet={webpSrcSet} type="image/webp" />}
          <source srcSet={srcSet} />
          <img
            width={computedWidth}
            height={computedHeight}
            src={src}
            alt={alt}
          />
        </picture>
      )}
    </StyledImage>
  )
}

Image.defaultProps = {
  width: null,
  height: null,
  src: null,
  src2x: null,
  webp: null,
  webp2x: null,
  responsive: false,
  placeholder: true,
}

Image.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  webp: PropTypes.string,
  webp2x: PropTypes.string,
  src: PropTypes.string,
  src2x: PropTypes.string,
  responsive: PropTypes.bool,
  alt: PropTypes.string.isRequired,
  placeholder: PropTypes.bool,
}

export default Image

export const StyledImage = styled.div`
  font-size: 0;
  line-height: 0;
  position: relative;
  background-color: ${(props) =>
    props.hasPlaceholder ? props.theme.placeholderColor : 'transparent'};
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  display: block;

  img {
    position: ${(props) => (props.auto ? 'static' : 'absolute')};
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
